.gallery{
    gap: 0px !important;
    margin: 0 !important;
    width: 98.7vw;
}
.gallery_img:nth-child(1),
.gallery_img:nth-child(11){
    width: 40vw !important;
    height: 40vw !important;
}
.gallery_img:nth-child(2),
.gallery_img:nth-child(3),
.gallery_img:nth-child(4),
.gallery_img:nth-child(5),
.gallery_img:nth-child(6),
.gallery_img:nth-child(7),
.gallery_img:nth-child(8),
.gallery_img:nth-child(9),
.gallery_img:nth-child(10),
.gallery_img:nth-child(12),
.gallery_img:nth-child(13),
.gallery_img:nth-child(14){
    width: 20vw !important;
    height: 20vw !important;
}
.gallery_img img:hover{
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
}
@media (max-width:900px) {
    .gallery_img:nth-child(1),
    .gallery_img:nth-child(8){
        width: 100vw !important;
        height: 100vw !important;
    }
    .gallery_img:nth-child(2),
    .gallery_img:nth-child(3),
    .gallery_img:nth-child(4),
    .gallery_img:nth-child(5),
    .gallery_img:nth-child(6),
    .gallery_img:nth-child(7),
    .gallery_img:nth-child(11),
    .gallery_img:nth-child(9),
    .gallery_img:nth-child(10),
    .gallery_img:nth-child(12),
    .gallery_img:nth-child(13),
    .gallery_img:nth-child(14){
        width: 50vw !important;
        height: 50vw !important;
    }
}

.gallery_dots{
    position: absolute;
    bottom: 30px;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
    padding: 12px 20px 8px !important;
    background-color: #fff !important;
    border-radius: 40px !important;
    z-index: 11;
}
.gallery_dots>div>div{
    cursor: pointer !important;
    height: 16px !important;
    width: 16px !important;
    margin: 0 4px !important;
}
.banner_silder>div>div{
    overflow: hidden !important;
}
@media (max-width:600px) {
    .gallery_dots{
        background-color: transparent !important;
        box-shadow: unset !important;
        bottom: 5px;
    }
    .gallery_dots>div{
        justify-content: center;
    }
    .gallery_dots>div>div{
        cursor: pointer !important;
        height: 10px !important;
        width: 10px !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 0 !important;
    }
    .gallery_dots>div>div:first-child{
        border-radius: 5px 0 0 5px!important;
    }
    .gallery_dots>div>div:last-child{
        border-radius: 0px 5px 5px 0px!important;
    }
}
.gallery_theme1_hover{
    overflow: hidden;
}
.gallery_theme1_hover:hover img{
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
}
.gallery_theme1_hover:hover .gallery_theme1_text{
    height: 100%;
    text-align: center;
    transition: all 0.3s ease-in-out;
}
.gallery_theme3{
    gap: 10px !important;
}
.gallery_theme3_hover{
    overflow: hidden;
    position: relative;
}
.gallery_theme3_hover:hover img{
    transform: scale(1.2);
    transition: all 0.5s ease-in-out;
}
.gallery_theme3_text{
    display: none !important;
}
.gallery_theme3_hover:hover .gallery_theme3_text{
    display: flex !important;
    flex-direction: column;
    height: 100%;
    text-align: center;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000094;
}
.gallery_theme4_hover{
    overflow: hidden;
    justify-content: center
}
.gallery_theme4_hover:hover img{
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
}
.gallery_theme4_text{
    display: none;
}
.gallery_theme4_hover:hover .gallery_theme4_text{
    display: flex !important;
    flex-direction: column;
    height: 100%;
    text-align: center;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000038;
}
.gallery_theme4_text_title{
    position: absolute;
    transform: translatey(-100px);
    }
.gallery_theme4_hover:hover .gallery_theme4_text_title{
    position: absolute;
    bottom: 50%;
    transform: translate(0px, 0px);
    transition: all 0.5s ease-in;
}

.gallery_theme5_hover{
    overflow: hidden;
    justify-content: center
}
.gallery_theme5_hover:hover img{
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
}
.gallery_theme5_text{
    display: none;
}
.gallery_theme5_hover:hover .gallery_theme5_text{
    display: flex !important;
    align-items: center;
    height: 100%;
    text-align: center;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000096;
}
.banner_dots_theme3 {
    position: absolute;
    border-radius: 10px !important;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
    bottom: 50px;
}
.banner_dots_theme3 button{
    padding: 8px 16px;
}
@media screen and (max-width:4000px) and (min-width:601px){
    .banner_dots_theme4 {
        position: absolute;
        border-radius: 10px !important;
        box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
        bottom: 50px;
    }
    .banner_dots_theme4 button{
        padding: 8px 16px;
    }
}
@media (max-width:600px) {
    .banner_dots_theme4{
        position: absolute;
        border-radius: 10px !important;
        bottom: 50px;
        box-shadow: unset !important;
        width: 90% !important;
        background: transparent !important;
    }
    .banner_dots_theme4 button{
        padding: 8px 16px;
    }
  }