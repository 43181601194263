body{
    background-color: black;
}
.our_blog_img{
    height: 480px;
}
.our_blog_img,.resevation{
    background-position:center;
    background-size:cover;
    background-repeat: no-repeat;
}
.resevation{
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 40%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.our_blog_description{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px;
}
h4 > p{
    margin: 0;
}
.OurBlogHeader> div > div{ 
    flex: 100% !important;
    width: 100% !important;
}
.res_close_modal div:nth-child(3) > div{
    /* min-width: 50% ;
    min-height: 50%; */
    max-height: 500px;
    width: 500px;
    margin: 0px;
}
.res_close_modal div:nth-child(3) > div > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.announcement div:nth-child(3) > div{
    min-width: 50% ;
    min-height: 50%;
}
.announcement div:nth-child(3) > div > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.banner_div{height:auto}
.banner_img{height:auto}

.banner_div1{height:auto}
.banner_img1{height:auto}
.banner_silder_video{overflow-x: unset !important ;height: 95vh;}
.banner_video_div{height:100%}
.close_modal_title{font-size: 2.5rem !important;}
.close_modal_des{font-size: 2.2rem !important;}

@media only screen and (max-width: 600px) {
    .banner_div{height:70vh}
    .banner_img{height:70vh}
    /* phone banner theme 1 & 5 css */
    .banner_div1{height:80vh}
    .banner_img1{height:80vh}

    .banner_silder_video{height:auto}

    .close_modal_title{font-size: 2.3rem !important;}
    .close_modal_des{font-size: 2rem !important;}
}
.res_cookies_modal{
    position: fixed;
    bottom: 20px;
    z-index: 9999;
    max-width: 24em;
    background-color: #fff !important;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 16px !important;
    width: 45%;
    flex-direction: column;
    text-align: center;
    left: 10px;

}
@media only screen and (max-width: 600px) {.res_cookies_modal{width: 95%;max-width: fit-content;}}
.res_cookies_modal div:nth-child(1){display: none;}
a{text-transform:unset !important}
/* *{font-family: 'Arial', sans-serif;padding: 0;margin: 0;} */