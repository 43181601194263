
.navbar_desktop{
    box-shadow: unset !important;
    top: 40px;
}
.locationHeaderManu,
.locationHeaderManu > div:nth-child(3),
.locationHeaderManu > div:nth-child(3) ul {
    box-shadow: unset;
    background-color: transparent !important;
}
@media (max-width:600px) {
    .navbar_desktop{
        top: 103px;
    }
}
.spareter_none a:nth-child(1) button p:nth-child(1){
    border-left: 0 !important;
}
.drawer_3 > div:nth-child(3){
    background-color: transparent !important;
}
.treeitemtheme4 > div:nth-child(1){
    display: flex;
    flex-direction: column;
}
.treeitemtheme4 > div:nth-child(1) > div:nth-child(1){
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: #fff;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-align: center;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 1.5rem;
    border-radius: 50%;
    overflow: visible;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 8px 16px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.treeitemtheme4 > div:nth-child(1) > div:nth-child(2){
    text-align: center;
}