.minibannerimagetop{
    bottom:97%;
}
.minibannerimagebottom{
    top:97%;
}
@media (max-width:1200px) {
    .minibannerimagetop{
        bottom:96%;
    }
    .minibannerimagebottom{
        top:96%;
    }
}
@media (max-width:900px) {
    .minibannerimagetop{
        bottom:98%;
    }
    .minibannerimagebottom{
        top:98%;
    }
}
@media (max-width:600px) {
    .minibannerimagetop{
        bottom:99%;
    }
    .minibannerimagebottom{
        top:99%;
    }
}
@media (max-width:768px) {
    .spanFontSize62pxrespo,
    .spanFontSize32pxrespo,
    .spanFontSize40pxrespo,
    .spanFontSize48pxrespo,
    .spanFontSize72pxrespo,
    .spanFontSize78pxrespo,
    .spanFontSize82pxrespo,
    .spanFontSize88pxrespo,
    .spanFontSize96pxrespo,
    .spanFontSize100pxrespo,
    .spanFontSize36pxrespo{
        font-size: 30px !important;
    }
}
.minibaner_btn_text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.minibaner_btn_text p{margin: 0;}

