.banner_main > div:nth-child(2){
    width: 100%;
}
.banner_dots{
    position: absolute;
    bottom: 30px;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
    padding: 12px 20px 8px !important;
    background-color: #fff !important;
    border-radius: 40px !important;
    z-index: 11;
}
.banner_dots>div>div{
    cursor: pointer !important;
    height: 16px !important;
    width: 16px !important;
    margin: 0 4px !important;
}
.banner_silder>div>div{
    overflow: hidden !important;
}
@media (max-width:600px) {
    .banner_dots{
        background-color: transparent !important;
        box-shadow: unset !important;
        bottom: 25px;
    }
    .banner_dots>div{
        justify-content: center;
    }
    .banner_dots>div>div{
        cursor: pointer !important;
        height: 10px !important;
        width: 30px !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 0 !important;
    }
    .banner_dots>div>div:first-child{
        border-radius: 5px 0 0 5px!important;
    }
    .banner_dots>div>div:last-child{
        border-radius: 0px 5px 5px 0px!important;
    }
}
.readMore_page_main{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 700px;
  }
  .readMore_page_main > div:nth-child(1){
    width: 100%;
    height: 700px;
  }
  .readMore_page_slider{
    width: 100%;
    height: 700px;
  }
  .readMore_page_slider > div:nth-child(1){
    height: 100%;
  }
  .readMore_page_slider > div:nth-child(1) > div,
  .readMore_page_slider > div:nth-child(1) > div > div,
  .readMore_page_slider > div:nth-child(1) > div > div > div
  {
    height: 100%;
  }
  .readMore_page_dots{
    position: absolute;
    bottom: 30px;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
    padding: 12px 20px 8px !important;
    background-color: #fff !important;
    border-radius: 40px !important;
    z-index: 11;
  }
  .readMore_page_dots>div>div{
    cursor: pointer !important;
    height: 16px !important;
    width: 16px !important;
    margin: 0 4px !important;
  }
  @media (max-width:1040px) {
    .readMore_page_dots>div>div{
      height: 8px !important;
      width: 8px !important;
    }
  }
  @media (max-width:900px) {
    .readMore_page_dots>div>div{
      height: 16px !important;
      width: 16px !important;
    }
  }
  @media (max-width:600px) {
    .readMore_page_dots>div>div{
      height: 8px !important;
      width: 8px !important;
    }
    .readMore_page_dots{
        background-color: transparent !important;
        box-shadow: unset !important;
        bottom: 5px;
    }
    .readMore_page_dots>div{
        justify-content: center;
    }
    .readMore_page_dots>div>div{
        cursor: pointer !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 0 !important;
    }
    .readMore_page_dots>div>div:first-child{
        border-radius: 5px 0 0 5px!important;
    }
    .readMore_page_dots>div>div:last-child{
        border-radius: 0px 5px 5px 0px!important;
    }
  }
  
.slide_banner_silder{
  width: 100%;
  height: 100%;
}
.silde_banner_main{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.silde_banner_main div:nth-child(2){
  width: 100%;
}
.slide_banner_dots{
  position: absolute;
  bottom: 30px;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
  padding: 12px 20px 8px !important;
  background-color: #fff !important;
  border-radius: 40px !important;
  z-index: 11;
}
.slide_banner_dots>div>div{
  cursor: pointer !important;
  height: 16px !important;
  width: 16px !important;
  margin: 0 4px !important;
}
@media (max-width:600px) {
  .slide_banner_dots{
      background-color: transparent !important;
      box-shadow: unset !important;
      bottom: 5px;
  }
  .slide_banner_dots>div{
      justify-content: center;
  }
  .slide_banner_dots>div>div{
      cursor: pointer !important;
      height: 10px !important;
      width: 30px !important;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
  }
  .slide_banner_dots>div>div:first-child{
      border-radius: 5px 0 0 5px!important;
  }
  .slide_banner_dots>div>div:last-child{
      border-radius: 0px 5px 5px 0px!important;
  }
}

.location_dots{
  position: absolute;
  bottom: 30px;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
  padding: 12px 20px 8px !important;
  background-color: #fff !important;
  border-radius: 40px !important;
  z-index: 11;
}
.location_dots>div>div{
  cursor: pointer !important;
  height: 16px !important;
  width: 16px !important;
  margin: 0 4px !important;
}
@media (max-width:600px) {
  .location_dots{
      background-color: transparent !important;
      box-shadow: unset !important;
      bottom: 5px;
  }
  .location_dots>div{
      justify-content: center;
  }
  .location_dots>div>div{
      cursor: pointer !important;
      height: 10px !important;
      width: 30px !important;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
  }
  .location_dots>div>div:first-child{
      border-radius: 5px 0 0 5px!important;
  }
  .location_dots>div>div:last-child{
      border-radius: 0px 5px 5px 0px!important;
  }
}
.OurBlog_dots{
  position: absolute;
  bottom: 30px;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
  padding: 12px 20px 8px !important;
  background-color: #fff !important;
  border-radius: 40px !important;
  z-index: 11;
}
.OurBlog_dots>div>div{
  cursor: pointer !important;
  height: 16px !important;
  width: 16px !important;
  margin: 0 4px !important;
}
@media (max-width:600px) {
  .OurBlog_dots{
      background-color: transparent !important;
      box-shadow: unset !important;
      bottom: 5px;
  }
  .OurBlog_dots>div{
      justify-content: center;
  }
  .OurBlog_dots>div>div{
      cursor: pointer !important;
      height: 10px !important;
      width: 30px !important;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
  }
  .OurBlog_dots>div>div:first-child{
      border-radius: 5px 0 0 5px!important;
  }
  .OurBlog_dots>div>div:last-child{
      border-radius: 0px 5px 5px 0px!important;
  }
}

.testimonials_dots{
  position: relative;
  bottom: -20px;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
  padding: 12px 20px 8px !important;
  background-color: #fff !important;
  border-radius: 40px !important;
  z-index: 11;
}
.testimonials_dots>div>div{
  cursor: pointer !important;
  height: 16px !important;
  width: 16px !important;
  margin: 0 4px !important;
}
.rating_star {
  width: 20px !important;
  margin: 5px;
}
@media (max-width:600px) {
  .testimonial_des {
    width: 90% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}
  .testimonials_dots{
      background-color: transparent !important;
      box-shadow: unset !important;
      bottom: 5px;
  }
  .testimonials_dots>div{
      justify-content: center;
  }
  .testimonials_dots>div>div{
      cursor: pointer !important;
      height: 10px !important;
      width: 30px !important;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
  }
  .testimonials_dots>div>div:first-child{
      border-radius: 5px 0 0 5px!important;
  }
  .testimonials_dots>div>div:last-child{
      border-radius: 0px 5px 5px 0px!important;
  }
}
@media (max-width:600px) {
  .banner_dots_theme3,.banner_dots_theme4,.banner_dots_theme5{bottom: 10px !important;}
}
.banner_dots_theme3 {
  position: absolute;
  border-radius: 10px !important;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
  bottom: 50px;
}
.banner_dots_theme3 button{
  padding: 8px 16px;
}
@media screen and (max-width:4000px) and (min-width:601px){
  .banner_dots_theme4 {
    position: absolute;
    border-radius: 10px !important;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
    bottom: 50px;
  }
  .banner_dots_theme4 button{
    padding: 8px 16px;
  }
}
@media (max-width:600px) {
  .banner_dots_theme4{
    position: absolute;
    border-radius: 10px !important;
    bottom: 10px !important;
    box-shadow: unset !important;
    width: 90% !important;
    background: transparent !important;
  }
  .banner_dots_theme4 button{
      padding: 8px 16px;
  }
}
.banner_dots_theme5{
  position: absolute;
  bottom: 50px;
  border-radius: 10px !important;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
}
.banner_dots_theme5 > div > div{
  width: 15px;
  height: 15px;
  border-radius: 0px !important;
  margin: 0 1px;
}
@media (max-width:450px) {
  .banner_dots_theme5{
    padding: 0 !important;
  }
  .banner_dots_theme5 > div > div{
    width: 10px;
    height: 10px;
    border-radius: 0px !important;
    margin: 0 1px;
  }
}
.banner_dots_theme1 button:nth-child(1){
  position: absolute;
  bottom: 100px;
  margin: auto;
  right: 100px; 
  height: fit-content;
  background-color: aliceblue;
  padding: 10px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.banner_dots_theme1 button:nth-child(3){
  position: absolute;
  bottom: 100px;
  margin: auto;
  right: 20px;
  height: fit-content;
  background-color: aliceblue;
  padding: 10px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.banner_dots_theme1 > span:nth-child(2){
  transform: rotate(90deg);
  position: absolute;
  width: 500px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  right: -200px;
  border-radius: 20px;
  background-color: #c5c6c8 !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.banner_dots_theme1 > span:nth-child(2) > span{
  background-color: #000 !important;
}
.banner_dots_theme1{
  background-color: transparent !important;
}
@media (max-width:900px) {
  .banner_dots_theme1 button:nth-child(1){
    bottom: 20px;
    right: 100px; 
  }
  .banner_dots_theme1 > span:nth-child(2){
    width: 300px;
    right: -120px;
  }
  .banner_dots_theme1 button:nth-child(3){
    bottom: 20px;
    right: 20px; 
  }  
}
@media (max-width:600px) {
  .banner_dots_theme1 button:nth-child(1){
    background-color: transparent;
    box-shadow: unset;
    bottom: 10px;
    right: 50px; 
  }
  .banner_dots_theme1 > span:nth-child(2){
    width: 100px;
    right: -25px;
  }
  .banner_dots_theme1 button:nth-child(3){
    background-color: transparent;
    box-shadow: unset;
    bottom: 10px;
    right: 0px; 
  }
}