.sectionitem:nth-child(1){
  margin-top: 150px;
}
.loading_div{ margin-top: 150px;}
.stepper-menu{
  width: calc(100% - 175px);
  display: flex;
}
.stepper-menu .step {
  margin: 10px 0;
  cursor: pointer;
  color: grey;
}
.stepper-menu .step.active {
  font-weight: bold;
  color: black;
}
.stepper-menu::-webkit-scrollbar,
.stepper-menu-scrollContainer::-webkit-scrollbar{
  display: none;
}
.stepper-menu,
.stepper-menu-scrollContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.stepper-main{
  width: calc(100% - 310.766px);
  margin: 0px 10px;
}
@media (max-width:1199px) {
  .stepper-main{
    width: 100%;
  } 
  .sectionitem:nth-child(1){
    margin-top: 150px;
  }
  }
  @media (max-width:599px) {
    .stepper-menu{
      display: none;
    }
    .sectionitem:nth-child(1){
      margin-top: 95px;
    }
    .loading_div{ margin-top: 95px;}
}
.menuItemsCard {
  width: 403.422px;
  height: 432.922px;
}
.menuItemsCard_layout2 {
  width: auto;
  height: 300.922px;
  text-align: center;
}
@media screen and (min-width:1200px) and (max-width:1300px){
  .menuItemsCard{
    width:100% !important;
  }
}

@media (max-width: 430px){   
    .menuItemsCard{
      width:100% !important;
    }
}
#long-menu div:nth-child(3)::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
#long-menu div:nth-child(3)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  background-color: #ebebeb;
  border-radius: 5px;
}
#long-menu div:nth-child(3)::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #6d6d6d;
}
#long-menu a .long-menu-hove:hover .long-menu-a,
#long-menu a .long-menu-hove:hover .long-menu-b,
#long-menu a .long-menu-hove:focus .long-menu-a,
#long-menu a .long-menu-hove:focus .long-menu-b{
  outline: 0;
  text-decoration: none;
  color: #ea6425;
}
.long-menu-active{
  font-weight: bolder !important;
  font-size: initial !important;
}
.Gluten_vegan_img{width: 25px;margin: 0px 10px;}
.itemname{display: flex;align-items: center;}

.popular_div{
  border-radius: 50px;
  float: right;
  background: black!important;
  color: #fff!important;
  padding: 5px 15px;
  display: inline;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}



