@font-face {font-family: 'Comic Sans MS'; src : local('Comic Sans MS'), url('font/Comic Sans MS.ttf') format('truetype')}
@font-face {font-family: 'Arial Black';src: local('Arial Black'), url('font/Arial Black.ttf') format('truetype')}
@font-face {font-family: 'Impact';src: local('Impact'), url('font/Impact.ttf') format('truetype')}
@font-face {font-family: 'Jost';src: local('Jost-VariableFont_wght'), url('font/Jost-VariableFont_wght.ttf') format('truetype')}
/* @import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap'); */
/* *{font-family: 'Arial', sans-serif;padding: 0;margin: 0;} */
/* *{ font-family: "Jost", sans-serif;font-weight: 300;padding: 0;margin: 0;font-size: 16px;} */
html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.skeleton{
  background-color: #cfcfcfd0 !important;
}
.title_textlimit{ 
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.des_textlimit{ 
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.div_css{width: 80%;}

.btn_text{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}