.section:nth-child(1){
  margin-top: 240px;
}
.section_layput2:nth-child(1){
  margin-top: 240px;
}
.stepper-menu{
  width: calc(100% - 175px);
  display: flex;
}
.stepper-menu .step {
  margin: 10px 0;
  cursor: pointer;
  color: grey;
}
.stepper-menu .step.active {
  font-weight: bold;
  color: black;
}
.stepper-menu::-webkit-scrollbar,
.stepper-menu-scrollContainer::-webkit-scrollbar{
  display: none;
}
.stepper-menu,
.stepper-menu-scrollContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.stepper-main{
  width: calc(100% - 310.766px);
  margin: 0px 10px;
}
@media (max-width:1199px) {
  .stepper-main{
    width: 100%;
  } 
  .section:nth-child(1){
    margin-top: 300px;
  }
  }
  @media (max-width:599px) {
    .stepper-menu{
      display: none;
    }
    .section:nth-child(1){
      margin-top: 220px;
    }
}
.menuItemsCard {
  width: 403.422px;
  height: 432.922px;
}
.menuItemsCard_layout2 {
  width: auto;
  height: 300.922px;
  text-align: center;
}
.menuItemsCard3 {
  height: 155px;
  display: flex
}
.menuItemsCard3_des{
  margin: 0 0 10px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.itemContent{width: calc(100% - 105px);}
.menuItemsCard2_2 {
  height: 260px;
}
.icon_img{width: 20px;margin: 3px;}
.section_layput2{text-align: center;}
@media screen and (min-width:1200px) and (max-width:1300px){
  .menuItemsCard,.menuItemsCard_layout2{
    width:100% !important;
  }
}

@media (max-width: 430px){   
    .menuItemsCard,.menuItemsCard_layout2{
      width:100% !important;
    }
}
#long-menu div:nth-child(3)::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
#long-menu div:nth-child(3)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  background-color: #ebebeb;
  border-radius: 5px;
}
#long-menu div:nth-child(3)::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #6d6d6d;
}
#long-menu a .long-menu-hove:hover .long-menu-a,
#long-menu a .long-menu-hove:hover .long-menu-b,
#long-menu a .long-menu-hove:focus .long-menu-a,
#long-menu a .long-menu-hove:focus .long-menu-b{
  outline: 0;
  text-decoration: none;
  color: #ea6425;
}
.long-menu-active{
  font-weight: bolder !important;
  font-size: initial !important;
}
.link2 .section{margin:0px !important;}
.layout2{margin: 10px 0px 50px 0px;}
.skeletonlayput1{margin: 150px 0px 50px 0px;}
/* .link2 .section div:nth-child(1){top:0 !important;} */