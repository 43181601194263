#Contact h4{
    font-size: 1.5em !important;
}
.panel2-header div:nth-child(1){
    flex: 90%;
}
@media (min-width:600px) and (max-width:899px) {
    .xs-map-img{
        width: 70%;
    }
    .xs-business-hours,.xs-carrout-hours{
        width: 70%;
        margin: auto;
    }
}
.fotterPrivacyandPolicyMenu{
    width: calc(100% - 165px);
}
@media (max-width:900px) {
    .fotterPrivacyandPolicyMenu{
        width:100%;
    }
}
.footerphonetext{font-size: 12px;}
.positivessl_footer_img{
    width:165px;
    right:0;
    top:0;
    bottom:0;
    padding: 10px 8px;
    margin:auto;
}
@media (max-width:900px) {
    .positivessl_footer_img{
        width:100px;
        margin-left: calc(100% - 110px);
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
    }
    .fotterPrivacyandPolicyMenuA{
        font-size: 10px !important;
    }
}